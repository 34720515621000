import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getPlupusExcel, getStudentList, getStudentListV2, getStudentListV2Excel} from "../../../api/plupus";
import {DownloadButton} from "../../elements/Buttons";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: false,
        enableColumnFilter: false,
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        sortingFn: "schoolClassSorting",
        filterFn: "containsString"
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
        filterFn: "containsString"
    },
    {
        accessorKey: "points_e1",
        id: "points_e1",
        header: "E1",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e1}{row.original.status_e1 === 'FORECAST' ? '*' : ''}</>
        ),
        statusClassNames: (row) => {
            return row.original.rating_e1 === "Grün" ? 'bg-green-50' : row.original.rating_e1 === "Gelb" ? 'bg-yellow-50' : 'bg-blue-50'
        }
    },
    {
        accessorKey: "points_e2",
        id: "points_e2",
        header: "E2",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e2}{row.original.status_e2 === 'FORECAST' ? '*' : ''}</>
        ),
        statusClassNames: (row) => {
            return row.original.rating_e2 === "Grün" ? 'bg-green-50' : row.original.rating_e2 === "Gelb" ? 'bg-yellow-50' : 'bg-blue-50'
        }
    },
    {
        accessorKey: "points_e3",
        id: "points_e3",
        header: "E3",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e3}{row.original.status_e3 === 'FORECAST' ? '*' : ''}</>
        ),
        statusClassNames: (row) => {
            return row.original.rating_e3 === "Grün" ? 'bg-green-50' : row.original.rating_e3 === "Gelb" ? 'bg-yellow-50' : 'bg-blue-50'
        }
    },
    {
        accessorKey: "points_e4",
        id: "points_e4",
        header: "E4",
        enableColumnFilter: false,
        columnType: "colouredStatus",
        cell: ({row, value}) => (
            <>{row.original.points_e4}{row.original.status_e4 === 'FORECAST' ? '*' : ''}</>
        ),
        statusClassNames: (row) => {
            return row.original.rating_e4 === "Grün" ? 'bg-green-50' : row.original.rating_e4 === "Gelb" ? 'bg-yellow-50' : 'bg-blue-50'
        }
    },
];

export default function StudentsV2() {
    const {permissions} = useAuth();
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus_v2", schoolYearId],
        () => getStudentListV2(schoolYearId),
        {
            enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60
        }
    );

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    function getRowLink(row) {
        return `/plupus/v2/students/${row.original.id}/`;
    }

    function getTrClassNames(row, index) {

        var current_rating = row.original.rating_e4
        if (row.original.status_e1 === 'CURRENT') {
            current_rating = row.original.rating_e1
        } else if (row.original.status_e2 === 'CURRENT') {
            current_rating = row.original.rating_e2
        } else if (row.original.status_e3 === 'CURRENT') {
            current_rating = row.original.rating_e3
        }

        return current_rating == "Grün" ? 'bg-green-50' : current_rating == "Gelb" ? 'bg-yellow-50' : 'bg-blue-50'
    }

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Plupus (Farbensystem) nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        <DownloadButton
                            apiFunction={() => getStudentListV2Excel(schoolYearId)}
                            fileName={`Plupus.xls`}
                            label="Liste herunterladen"/>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                                minSchoolYearId={39}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getTrClassNames={getTrClassNames}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
