import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {getLogbookEntries} from "../../../api/logbook";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable, {TableEditLink, TwoLineColumn} from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import {Link, useParams} from "react-router-dom";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import Pagination from "../../elements/Pagination";
import {getSchoolClassesFull} from "../../../api/school";
import {houseApi} from "../../../api/boarding_school";
import {SecondaryButton} from "../../elements/Buttons";

const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: "student",
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "category.name",
        id: "category_name",
        header: "Kategorie",
        enableColumnFilter: true,
        headerClassNames: "medium-filter",
    },
    {
        accessorKey: "creation_date",
        id: "creation_date",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        // classNames: 'font-medium text-gray-900'
        classNames: "text-gray-500",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "get_visibility_display",
        id: "get_visibility_display",
        header: "Sichtbarkeit",
        cell: (info) => info.getValue(),
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },

];

const reducedColumns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Titel",
        enableColumnFilter: false,
        classNames: "max-w-md text-gray-500",
        headerClassNames: "max-w-md",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by",
        header: "Eintrag von",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
    }
]

const extendedColumns = [
    {
        accessorKey: "title",
        id: "title",
        header: "Eintrag",
        cell: ({row, value}) => (
            <TwoLineColumn
                line1={row.original.title}
                line2={row.original.entry}
            />),
        enableColumnFilter: false,
        classNames: "max-w-lg text-gray-500",
        headerClassNames: "max-w-lg",
    },
    {
        accessorKey: "number_of_penalties",
        id: "number_of_penalties",
        header: "Strafeinträge",
        enableColumnFilter: false,
        classNames: "max-w-md text-gray-500",
        headerClassNames: "max-w-md",
    },
    {
        accessorKey: "created_by_full_name",
        id: "created_by",
        header: "Eintrag von",
        enableColumnFilter: false,
        headerClassNames: "medium-filter",
    }
]

export default function LogbookEntries() {
    const {studentId} = useParams();
    const {permissions} = useAuth();
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [rowSelection, setRowSelection] = useState([]);
    const [schoolYearId, setSchoolYearId] = useState();
    const [data, setData] = useState([])
    const [filters, setFilters] = useState([])
    const [searchQuery, setSearchQuery] = useState("")
    const [totalCount, setTotalCount] = useState(0)
    const [classes, setClasses] = useState([])
    const [houses, setHouses] = useState([])
    const [page, setPage] = useState(1);
    const [extended, setExtended] = useState(false);

    const canEditLogbookEntries = checkPermission(permissions, "student_logbook.change_logbookentry")

    let editColumns = canEditLogbookEntries ? [
        {
            accessorKey: "id",
            id: "link",
            cell: (info) => (
                <TableEditLink
                    label="Bearbeiten"
                    iconOnly={true}
                    to={`/logbook/entries/${info.getValue()}/edit`}
                />
            ),
            header: "Bearbeiten",
            enableColumnFilter: false,
            disableRowLink: true
        }
    ] : []

    const complColumns = extended ? [...columns, ...extendedColumns, ...editColumns] : [...columns, ...reducedColumns, ...editColumns]

    const {data: classesRawData} = useQuery(
        ["classes_data"],
        () => getSchoolClassesFull(), {
            enabled: true
        }
    )

    const {data: housesRawData} = useQuery(
        ["houses_data"],
        () => houseApi('get', null, null), {
            enabled: true
        }
    )

    const {isLoading, isError, isSuccess, data: rawData, error, refetch} = useQuery(
        ["logbook_entries", schoolYearId, page, searchQuery, filters],
        () => getLogbookEntries(schoolYearId, page, searchQuery, filters),
        {
            enabled: !!schoolYearId,
            keepPreviousData : true
        }
    )
    
    useEffect(() => {
        setData(rawData?.results.length ? rawData.results : [])
        setTotalCount(rawData?.count)
    }, [rawData])

    useEffect(() => {
        refetch()
    }, [page, searchQuery, filters])

    useEffect(() => {
        setPage(1) // page needs to be reset when search or filters change
    }, [searchQuery, filters])

    useEffect(() => {
        !!classesRawData?.length && setClasses(classesRawData.map(el => ({id: el.id, value: el.group})))
    }, [classesRawData])

    useEffect(() => {
        !!housesRawData?.length && setHouses(housesRawData.map(el => ({id: el.id, value: el.name})))
    }, [housesRawData])

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    const securityList = [
        {id: 'MANAGEMENT', value: 'nur Leitung'},
        {id: 'STAFF', value: 'Team und Leitung'},
        {id: 'ALL', value: 'Eltern, Schüler, Team'},
    ]

    function getRowLink(row) {
        return `/logbook/entries/${row.original.id}/`;
    }

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Logbucheinträge {studentId && "von " + data[0].student.full_name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">

                        <SecondaryButton
                            classNameIn="mr-2"
                            onClick={() => setExtended(!extended)} label={extended ? "Einfache Ansicht" : "Erweiterte Ansicht"} />

                        {checkPermission(
                            permissions,
                            "student_logbook.add_logbookentry"
                        ) && (
                            <Link to="/logbook/entries/new">
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                                >
                                    Neuer Eintrag
                                </button>
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            <Pagination
                                page={page} setPage={setPage}
                                pageSize={50} totalResults={totalCount}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    filterMode={"api"}
                                    data={data}
                                    columns={complColumns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    rowSelection={rowSelection}
                                    setRowSelection={setRowSelection}
                                    getRowLink={getRowLink}
                                    searchQuery={searchQuery}
                                    setSearchQuery={setSearchQuery}
                                    filters={filters}
                                    setFilters={setFilters}
                                    classes={classes}
                                    houses={houses}
                                    securityList={securityList}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
