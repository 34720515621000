import { SimplePage } from "../../elements/Pages";
import React, { useState } from "react";

import {
  getLogbookStudentList,
  getLogbookStudentSummaries,
} from "../../../api/logbook";

import { useQuery, refetch } from "@tanstack/react-query";
import { useAuth } from "../../../contexts/AuthContext";
import ListViewTable, { TableLink } from "../../elements/tables/ListViewTable";
import checkPermission from "../../../utils/permissions";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import { useEffect } from "react";
import { useSchoolYears } from "../../../contexts/SchoolYearContext";

const columns = [
  // {
  //     accessorKey: 'id',
  //     id: 'select',
  //     columnType: 'select'
  // },
  {
    accessorFn: (row) =>
      `${row.last_name}, ${row.first_name} ${row.student_number}`,
    id: "student",
    header: "SchülerIn",
    enableColumnFilter: false,
  },
  // {
  //     accessorKey: 'student',
  //     id: 'student',
  //     header: 'SchülerIn',
  //     columnType: 'student',
  //     enableColumnFilter: false,
  // sortingFn: "lastNameSorting",
  // },
  {
    accessorKey: "house",
    id: "house",
    header: "Mentorat",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "school_class",
    id: "school_class",
    header: "Klasse",
    enableColumnFilter: true,
    sortingFn: "schoolClassSorting",
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "number_of_penalties",
    id: "number_of_penalties",
    header: "Strafeinträge",
    enableColumnFilter: false,
  },
  {
    accessorKey: "penalties_open",
    id: "penalties_open",
    header: "Offen",
    enableColumnFilter: false,
  },
  {
    accessorKey: "penalties_compensated",
    id: "penalties_compensated",
    header: "Abgeleistet",
    enableColumnFilter: false,
  },
  {
    accessorKey: "penalties_time_barred",
    id: "penalties_time_barred",
    header: "Verjährt",
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) =>
      `${row.count_penalties_open}/${row.count_penalties_compensated}/${row.compensated_penalties_value}`,
    id: "penalties",
    header: "Strafen (offen/abgeleistet/Wert abgeleistet)",
    enableColumnFilter: false,
    classNames: "max-w-sm",
    headerClassNames: "max-w-sm white-space-pre-line",
  },
  {
    accessorKey: "bsw",
    id: "bsw",
    header: "Bes. schul. Würdigung",
    enableColumnFilter: false,
  },
  {
    accessorKey: "total",
    id: "total",
    header: "Saldo",
    enableColumnFilter: false,
  },
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <>
        <TableLink
          label="Logbuch ansehen"
          to={`/logbook/students/${info.getValue()}`}
        />
        <br />
        <TableLink
          label="Neuer Logbucheintrag"
          to={`/logbook/entries/new/${info.getValue()}`}
        />
        <br />
        <TableLink
          label="Strafe eintragen"
          to={`/logbook/penalties/new/${info.getValue()}`}
        />
      </>
    ),

    header: "Details",
    enableColumnFilter: false,
    classNames: "max-w-lg",
    headerClassNames: "max-w-lg",
    disableRowLink: true
  },
];

export default function Students() {
  const { permissions } = useAuth();
  const { schoolYears, currentSchoolYear } = useSchoolYears();
  const [schoolYearId, setSchoolYearId] = useState();

  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["logbook_students", schoolYearId],
    () => getLogbookStudentList(schoolYearId),
    {
      enabled: !!schoolYearId, refetchInterval: 1000 * 60 * 60 * 24
    }
  );

  useEffect(() => {
    if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
  }, [currentSchoolYear]);

  function getRowLink(row) {
    return `/logbook/students/${row.original.id}/`;
  }
  // if (!data) return <></>
  return (
    <SimplePage>
      {/*  Simple striped table */}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Schüler
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {checkPermission(
              permissions,
              "student_logbook.add_logbookentry"
            ) && (
              <Link to="/logbook/entries/new">
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Neuer Eintrag
                </button>
              </Link>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <SchoolYearFilter
                schoolYears={schoolYears}
                schoolYearId={schoolYearId}
                setSchoolYearId={setSchoolYearId}
              />
            </div>

            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  getRowLink={getRowLink}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
