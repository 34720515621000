import {SimplePage} from "../../elements/Pages";
import React from 'react';

import {useQuery} from '@tanstack/react-query'
import {Link, useNavigate} from "react-router-dom";
import {getMyStudents} from "../../../api/school";
import {useAuth} from "../../../contexts/AuthContext";
import {getStudentListMentor} from "../../../api/boarding_school";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {useUserInfo} from "../../../contexts/UserInfoContext";

const columns = [
    {
        accessorFn: (row) =>
            `${row.last_name}, ${row.first_name} ${row.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorFn: (row) => row,
        id: "student",
        header: "SchülerIn",
        columnType: "student",
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "grade",
        id: "grade",
        header: "Stufe",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        sortingFn: "schoolClassSorting",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "main_mentor",
        id: "main_mentor",
        header: "Stammmentor",
        enableColumnFilter: true,
    },
    {
        accessorKey: "id",
        id: "link",
        cell: (info) => (
            <TableLink label="Logbuch" to={`/logbook/students/${info.getValue()}`} />
        ),
        header: "Logbuch",
        enableColumnFilter: false,
        disableRowLink: true
    },
    {
        accessorKey: "id",
        id: "hw",
        cell: (info) => (
            <TableLink
                label="Hausaufgaben"
                to={`/class-register/students/${info.getValue()}`}
            />
        ),
        disableRowLink: true,
        header: "Hausaufgaben",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "hw",
        cell: (info) => (
            <TableLink
                label="Notenblatt"
                to={`/grades/students/${info.getValue()}/notenblatt`}
            />
        ),
        disableRowLink: true,
        header: "Notenblatt",
        enableColumnFilter: false,
    },
    {
        accessorKey: "id",
        id: "assessments",
        cell: (info) => (
            <TableLink
                label="Beurteilungen"
                to={`/assessments/students/${info.getValue()}`}
            />
        ),
        disableRowLink: true,
        header: "Beurteilungen",
        enableColumnFilter: false,
    },
];

export default function MyHouse() {

    const {currentUser} = useAuth()
    const {renderUserImage} = useUserInfo()
    const navigate = useNavigate()
    const {data: students, isSuccess, isLoading} = useQuery(['my_students', currentUser.user_id], getMyStudents, {refetchInterval: 1000 * 60 * 60 * 24})

    const {
        data: mentorHouses,
        isSuccess: isSuccessHouses,
        isLoading: isLoadingHouses
    } = useQuery(['my_house_students', currentUser.user_id], getStudentListMentor)

    function getLink(studentId) {
        return `/boarding-school/houses/students/${studentId}/`
    }

    if (isSuccess && students.length === 1) {
        navigate(getLink(students[0].id))
    }

    function getRowLink(row) {
        return `/school/students/${row.original.id}/`;
    }

    if (isLoading || isLoadingHouses) return <></>

    return (
        <SimplePage>
            {students.length > 0 && isSuccess && <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Mein Mentorat</h3>
                    </div>
                </div>
                <div className="flex flex-col max-w-sm">
                    <ul role="list" className="divide-y divide-gray-200">
                        {isSuccess && students && students.map((student) => (
                            <li key={student.id} className="flex py-4">
                                <Link key={student.id} to={getLink(student.id)} className="flex">
                                    {renderUserImage(student.user_id)}
                                    <div className="ml-3">
                                        <p className="text-sm font-medium text-gray-900">{student.full_name}</p>
                                        <p className="text-sm text-gray-500">{student.student_number}</p>
                                    </div>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>}

            {mentorHouses?.length > 0 && isSuccessHouses &&
                <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Mein Mentorat</h3>
                        </div>
                    </div>
                    <div className="flex flex-col max-w-full">

                        {isSuccessHouses && mentorHouses.map((house, index) => (

                            <>
                                <h4 className="mt-4 text-md leading-6 font-medium text-gray-900">{house.name}</h4>
                                <ListViewTable
                                    key={index}
                                    data={house.students}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={true}
                                    getRowLink={getRowLink}
                                    noTopSpace={true}
                                />
                            </>

                        ))}
                    </div>
                </div>}
        </SimplePage>
    )
}
