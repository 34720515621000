import {SimplePage} from "../../elements/Pages";
import React, {useEffect, useState} from "react";

import {useQuery} from "@tanstack/react-query";
import {useAuth} from "../../../contexts/AuthContext";
import ListViewTable from "../../elements/tables/ListViewTable";
import {useParams} from "react-router-dom";
import SchoolYearFilter from "../../elements/SchoolYearFilter";
import {useSchoolYears} from "../../../contexts/SchoolYearContext";
import {getStudentDetail} from "../../../api/plupus";

const columns = [
    {
        accessorKey: 'get_type_display',
        id: 'get_type_display',
        header: 'Art',
        enableColumnFilter: true,
    },
    {
        accessorKey: "details",
        id: "details",
        header: "Details",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "last_updated",
        id: "last_updated",
        header: "Datum",
        columnType: "date",
        enableColumnFilter: false,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "time_period_name",
        id: "time_period_name",
        header: "Epoche",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "points",
        id: "points",
        header: "Punkte",
        enableColumnFilter: false,
    }
];

export default function Students() {

    const {studentId} = useParams()
    const {schoolYears, currentSchoolYear} = useSchoolYears();
    const [schoolYearId, setSchoolYearId] = useState();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["plupus", studentId, schoolYearId],
        () => getStudentDetail(schoolYearId, studentId),
        {
            enabled: !!schoolYearId,
        }
    );

    useEffect(() => {
        if (currentSchoolYear) setSchoolYearId(currentSchoolYear["id"]);
    }, [currentSchoolYear]);

    if (!data) return <></>
    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Plupus für {data.student.full_name}
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block py-2 align-middle md:px-6 lg:px-8">
                            <SchoolYearFilter
                                schoolYears={schoolYears}
                                schoolYearId={schoolYearId}
                                setSchoolYearId={setSchoolYearId}
                            />
                        </div>

                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data.plupu_lines}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={null}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
