import { SimplePage } from "../../elements/Pages";
import React from "react";

import { useQuery } from "@tanstack/react-query";
import ListViewTable, { TableLink } from "../../elements/tables/ListViewTable";
import { getSmokers } from "../../../api/school";
import checkPermission from "../../../utils/permissions";
import { getStudentsMainMentors } from "../../../api/boarding_school";

const columns = [
  {
    accessorFn: (row) =>
      `${row.last_name}, ${row.first_name} ${row.student_number}`,
    id: "student_search",
    header: "SchülerIn",
    hide: true,
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => row,
    id: "student",
    header: "SchülerIn",
    columnType: "student",
    enableColumnFilter: false,
    sortingFn: "lastNameSorting",
  },
  {
    accessorKey: "grade",
    id: "grade",
    header: "Stufe",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "school_class",
    id: "school_class",
    header: "Klasse",
    enableColumnFilter: true,
    sortingFn: "schoolClassSorting",
    headerClassNames: "narrow-filter",
  },
];

const additionalColumns = [
  {
    accessorKey: "house",
    id: "house",
    header: "Mentorat",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "main_mentor",
    id: "main_mentor",
    header: "Stammmentor",
    enableColumnFilter: true,
    filterFn: "containsString",
  },
];

const editColumn = [
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <TableLink label="Logbuch" to={`/logbook/students/${info.getValue()}`} />
    ),
    header: "Logbuch",
    enableColumnFilter: false,
    disableRowLink: true
  },
  {
    accessorKey: "id",
    id: "cr",
    cell: (info) => (
        <TableLink
            label="Abwesenheiten"
            to={`/logbook/absence-notices/students/${info.getValue()}`}
        />
    ),
    disableRowLink: true,
    header: "Abwesenheiten",
    enableColumnFilter: false,
  },
  {
    accessorKey: "id",
    id: "hw",
    cell: (info) => (
        <TableLink
            label="Hausaufgaben"
            to={`/class-register/students/${info.getValue()}`}
        />
    ),
    disableRowLink: true,
    header: "Hausaufgaben",
    enableColumnFilter: false,
  },
  {
    accessorKey: "id",
    id: "hw",
    cell: (info) => (
        <TableLink
            label="Notenblatt"
            to={`/grades/students/${info.getValue()}/notenblatt`}
        />
    ),
    disableRowLink: true,
    header: "Notenblatt",
    enableColumnFilter: false,
  },
  {
    accessorKey: "id",
    id: "assessments",
    cell: (info) => (
        <TableLink
            label="Beurteilungen"
            to={`/assessments/students/${info.getValue()}`}
        />
    ),
    disableRowLink: true,
    header: "Beurteilungen",
    enableColumnFilter: false,
  },
];

export default function MainMentors() {
  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["students_main_mentors"],
    () => getStudentsMainMentors(),
      {refetchInterval: 1000 * 60 * 60}
  );

  function getRowLink(row) {
    return `/school/students/${row.original.id}/`;
  }

  if (!data) return <></>;

  // only show the additional columns if the viewing user is not a mentor
  const tableColumns = data.show_all
    ? columns.concat(additionalColumns).concat(editColumn)
    : columns.concat(editColumn);

  return (
    <SimplePage>
      {/*  Simple striped table */}
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {data.show_all ? "Stammmentoren" : "Meine Mentis"}
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {/*{checkPermission(permissions, 'student_logbook.add_logbookentry') &&*/}
            {/*    <Link to="/logbook/entries/new">*/}
            {/*        <button*/}
            {/*            type="button"*/}
            {/*            className="inline-flex items-center justify-center rounded-md border border-transparent bg-imsblue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-imsblue-700 focus:outline-none focus:ring-2 focus:ring-imsblue-500 focus:ring-offset-2 sm:w-auto"*/}
            {/*        >Neuer Eintrag*/}
            {/*        </button>*/}
            {/*    </Link>}*/}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data.students}
                  columns={tableColumns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  getRowLink={getRowLink}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
