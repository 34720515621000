import http from "./../services/httpService";


export async function getAttendedOptions() {
    const resp = await http.get('/attended_options/')
    return resp.data
}

export async function getMaritalStatusOptions() {
    const resp = await http.get('/utils/marital_status_options/')
    return resp.data
}

export function getFiltersString(filters) {
    return filters.map(el => `${el.id}=${el.value}`).join('&')
}

const filtersMap = {
    category_name: 'category__name',
    house: 'student__house',
    school_class: 'student__school_class',
    get_visibility_display: 'visibility',
}

export function getRealFiltersName(filters) {
    return filters.map(el => ({id: filtersMap[el.id], value: el.value}))
}

