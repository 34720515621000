import { SimplePage } from "../../elements/Pages";
import React from "react";

import { useQuery } from "@tanstack/react-query";
import ListViewTable, { TableLink } from "../../elements/tables/ListViewTable";
import {getSmokers, getSmokersExcel} from "../../../api/school";
import {DownloadButton} from "../../elements/Buttons";
import {getTaxiListPDF} from "../../../api/boarding_school";

const columns = [
  {
    accessorFn: (row) =>
      `${row.last_name}, ${row.first_name} ${row.student_number}`,
    id: "student_search",
    header: "SchülerIn",
    hide: true,
    enableColumnFilter: false,
  },
  {
    accessorFn: (row) => row,
    id: "student",
    header: "SchülerIn",
    columnType: "student",
    enableColumnFilter: false,
    sortingFn: "lastNameSorting",
  },
  {
    accessorKey: "house",
    id: "house",
    header: "Mentorat",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "grade",
    id: "grade",
    header: "Stufe",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
  },
  {
    accessorKey: "school_class",
    id: "school_class",
    header: "Klasse",
    enableColumnFilter: true,
    headerClassNames: "narrow-filter",
    sortingFn: "schoolClassSorting",
    filterFn: "containsString",
  },
  {
    accessorKey: "id",
    id: "link",
    cell: (info) => (
      <TableLink label="Logbuch" to={`/logbook/students/${info.getValue()}`} />
    ),
    header: "Logbuch",
    enableColumnFilter: false,
    disableRowLink: true
  },
];

export default function Smokers() {
  const { isLoading, isError, isSuccess, data, error, refetch } = useQuery(
    ["smokers"],
    () => getSmokers(),
      {refetchInterval: 1000 * 60 * 60 * 24}
  );

  function getRowLink(row) {
    return `/school/students/${row.original.id}/`;
  }

  return (
    <SimplePage>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Raucherliste
            </h3>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <DownloadButton
                apiFunction={() => getSmokersExcel()}
                fileName="Raucherliste.xls"
                label="Excel herunterladen"/>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              {isSuccess && data && (
                <ListViewTable
                  data={data}
                  columns={columns}
                  isLoading={isLoading}
                  disableGlobalSearch={false}
                  getRowLink={getRowLink}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </SimplePage>
  );
}
