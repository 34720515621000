import http from "./../services/httpService";
import {genericApi} from "./generic";
import { getFiltersString, getRealFiltersName } from "./utils";

// Logbook entries
export async function getLogbookEntryCategories() {
    const resp = await http.get('/logbook_entry_categories/')
    return resp.data
}

export async function getLogbookEntries(schoolYearId, page, search = '', filters = []) {
    const resp = await http.get(`/logbook_entries/`
        // + (extended ? 'extended/' : '')
        + (schoolYearId ? `?school_year_id=${schoolYearId}` : '')
        + (page > 1 ? `&page=${page}` : '')
        + (search ? `&search=${search}` : '')
        + (filters.length ? `&${getFiltersString(getRealFiltersName(filters))}` : '')
    )
    return resp.data
}

export async function getLogbookEntry(id) {
    const resp = await http.get(`/logbook_entries/${id}/`)
    return resp.data
}

export async function getLogbookEntryTimeline(id) {
    const resp = await http.get(`/logbook_entries/${id}/timeline/`)
    return resp.data
}

export async function postLogbookEntry(data) {
    const resp = await http.post('/logbook_entries/', data)
    return resp.data
}

export async function patchLogbookEntry(data) {
    const resp = await http.patch(`/logbook_entries/${data.id}/` , data)
    return resp.data
}

export async function deleteLogbookEntry(id) {
    const resp = await http.delete(`/logbook_entries/${id}/`)
    return resp.data
}

export async function getLogbookStudentList(schoolYearId) {
    const resp = await http.get(`/student_logbook/students/` + (schoolYearId ? `?school_year_id=${schoolYearId}` : ''))
    return resp.data
}

export async function getLogbookStudentDetail(studentId, categoryName) {
    const resp = await http.get('/logbook_entries/?student_id=' + studentId + (categoryName ? `&category__name=${categoryName}` : ''))
    return resp.data
}

export async function getLogbookStudentSummaries(studentId) {
    const resp = await http.get(`/student_logbook/students/${studentId}/summaries/`)
    return resp.data
}

// PE Exemptions
export async function getPeExemptions(studentId) {
    const resp = await http.get('/pe_exemptions/' + (studentId ? `?student_id=${studentId}` : ''))
    return resp.data
}

export async function getPeExemption(id) {
    const resp = await http.get(`/pe_exemptions/${id}/`)
    return resp.data
}

export async function getMyPeExemptions() {
    const resp = await http.get('/pe_exemptions/my/')
    return resp.data
}

export async function postPeExemption(data) {
    const resp = await http.post('/pe_exemptions/', data)
    return resp.data
}

export async function patchPeExemption(data) {
    const resp = await http.patch(`/pe_exemptions/${data.id}/` , data)
    return resp.data
}

export async function deletePeExemption(id) {
    const resp = await http.delete(`/pe_exemptions/${id}/`)
    return resp.data
}

// Absence notices
export async function getAbsenceNotices(futureOrToday) {
    const resp = await http.get(`/absence_notices/${futureOrToday}/`)
    return resp.data
}

export async function getAbsenceNoticesForStudent(studentId) {
    const resp = await http.get(`/absence_notices/` + (studentId ? `?student_id=${studentId}` : ''))
    return resp.data
}

export async function getAbsenceNoticeSummary(studentId) {
    const resp = await http.get(`/student_logbook/absence_notices/${studentId}/summaries/`)
    return resp.data
}

export async function absenceNoticeApi(method, id, data) {
    return genericApi('/absence_notices/', method, id, data)
}

export async function deleteAbsenceNotices(data) {
    const resp = await http.post(`/absence_notices/delete_multiple/`, data)
    return resp.data
}

export async function cancelAbsenceNoticeOccurrence(id, data) {
    const resp = await http.post(`/absence_notices/${id}/cancel_occurrence/`, data)
    return resp.data
}

export async function uncancelAbsenceNoticeOccurrence(id, data) {
    const resp = await http.post(`/absence_notices/${id}/uncancel_occurrence/`, data)
    return resp.data
}

export async function getAbsenceNoticeReasons() {
    const resp = await http.get('/absence_notice_reasons/')
    return resp.data
}

export async function getAbsenceNoticeReasonsAbsencesOnly() {
    const resp = await http.get('/absence_notice_reasons/counts_as_absence/')
    return resp.data
}

// Penalties
export async function penaltiesApi(method, id, data) {
    return genericApi('/penalties/', method, id, data)
}

export async function getPenalties(uncompensatedOrAll, penaltyName) {
    const resp = await http.get('/penalties/'  + (uncompensatedOrAll === 'uncompensated' ? 'uncompensated/' : '') + (penaltyName ? `?penalty=${penaltyName}` : ''))
    return resp.data
}

export async function getPenaltyCategories() {
    const resp = await http.get('/penalty_categories/')
    return resp.data
}

// Watchlist entries
export async function watchlistApi(method, id, data) {
    return genericApi('/watchlist/', method, id, data)
}

export async function getAbsenceNoticeFeed() {
    const resp = await http.get('/absence_notices/feed/')
    return resp.data
}

export async function getPenaltyFeed() {
    const resp = await http.get('/penalties/feed/')
    return resp.data
}

export async function getBonusEntriesStatus() {
    const resp = await http.get('/student_logbook/bonus_entries_status/')
    return resp.data
}