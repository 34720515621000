import {SimplePage} from "../../elements/Pages";
import React from "react";

import {useQuery} from "@tanstack/react-query";
import ListViewTable, {TableLink} from "../../elements/tables/ListViewTable";
import {
    getParticipantsExcel,
    getProjectParticipationExcel,
    getProjectsExcel, getProjectsStudentsPDF,
    getProjectStudents
} from "../../../api/projects";
import {DownloadButton} from "../../elements/Buttons";
import checkPermission from "../../../utils/permissions";
import {useAuth} from "../../../contexts/AuthContext";

const columns = [
    {
        accessorFn: (row) =>
            `${row.student.last_name}, ${row.student.first_name} ${row.student.student_number}`,
        id: "student_search",
        header: "SchülerIn",
        hide: true,
        enableColumnFilter: false,
    },
    {
        accessorKey: 'student',
        id: 'student',
        header: 'SchülerIn',
        columnType: 'student',
        enableColumnFilter: false,
        sortingFn: "lastNameSorting",
    },
    {
        accessorKey: "student.school_class",
        id: "school_class",
        header: "Klasse",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.house",
        id: "house",
        header: "Mentorat",
        enableColumnFilter: true,
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "has_project_requirements_fulfilled",
        id: "has_project_requirements_fulfilled",
        header: "PV erfüllt",
        columnType: "yesno",
        enableColumnFilter: true,
        filterFn: "boolean",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "is_projects_validated",
        id: "is_projects_validated",
        header: "Validiert",
        enableColumnFilter: false,
        columnType: "yesno",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "projects",
        id: "project_times",
        header: "Zeit",
        enableColumnFilter: false,
        columnType: "m2m",
        separator: "<br/>",
        labelAttr: "time",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "projects",
        id: "projects",
        header: "Projekte",
        enableColumnFilter: false,
        columnType: "m2mLinked",
        separator: "<br/>",
        getItemLink: (item) => {
            return `/projects/${item.id}/`
        },
        labelAttr: "name",
        headerClassNames: "narrow-filter",
    },
    {
        accessorKey: "student.id",
        id: "link",
        cell: (info) => (
            <>
                <TableLink
                    label="Anwesenheit"
                    to={`/projects/attendance/students/${info.getValue()}`}
                />
            </>
        ),
        header: "Anwesenheit",
        enableColumnFilter: false,
        classNames: "max-w-lg",
        headerClassNames: "max-w-lg",
        disableRowLink: true
    },
];

export default function Students() {

    const {permissions} = useAuth();

    const {isLoading, isError, isSuccess, data, error, refetch} = useQuery(
        ["projects_students"],
        getProjectStudents,
        {refetchInterval: 1000 * 60 * 60 * 24}
    )

    function getRowLink(row) {
        return `/projects/students/${row.original.student.id}`;
    }

    const canViewParticipation = checkPermission(permissions, "projects.view_projectparticipation");

    return (
        <SimplePage>
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">
                            Projekte nach Schülern
                        </h3>
                    </div>
                    <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            {canViewParticipation && <DownloadButton
                                apiFunction={getProjectsStudentsPDF}
                                fileName="Schülerprojektliste.pdf"
                                label="PDF herunterladen"
                            />}
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                            {isSuccess && data && (
                                <ListViewTable
                                    data={data}
                                    columns={columns}
                                    isLoading={isLoading}
                                    disableGlobalSearch={false}
                                    getRowLink={getRowLink}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </SimplePage>
    );
}
